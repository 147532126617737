
import { defineComponent, computed, ref, ComputedRef, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { StudentAccountInformation } from "@/store/vm/account/AccountModule";
import { AccountActions } from "@/store/vm/account/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import { People } from "@/store/vm/people/PeopleModule";

export default defineComponent({
  name: "mono-create-account-student",
  emits: ["change"],
  props: {
    information: {
      required: true,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const hasResponse = ref(false);
    const isSuccess = ref(false);
    const similarPeople = ref({
      avatar: "",
      first_name: "",
      last_name: "",
      birth_date: 0,
      email: "",
      phone: "",
      gender: "",
      id: 0,
    });

    const isUsernameExistValid = ref(false);
    const isSelectUsernameExisted = ref(false);
    const message = ref("");

    const defaultData = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      role: "student",
      gender: "male",
      birthday: "2021-01-01",
    };

    const formData = ref({ ...defaultData });

    const account = computed(() => {
      return props.information;
    }) as ComputedRef<StudentAccountInformation>;

    const labelGenders = [
      {
        value: "male",
        label: "Nam",
      },
      {
        value: "female",
        label: "Nữ",
      },
    ];

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const validateVietnamPhone = (str) => {
      const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/gm;
      let m;
      let isMatching = false;
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          isMatching = true;
        });
      }
      return isMatching;
    };

    var validateUsername = (rule, value, callback) => {
      if (validateEmail(value) || validateVietnamPhone(value)) {
        callback();
      } else {
        callback(
          new Error("Username cần có định dạng email hoặc số điện thoại!")
        );
      }
    };

    var transformBirthday = (value) => {
      const date = value.split("/").reverse().join("-");
      return Date.parse(date) ? date : "2021-01-01";
    };

    const rules = ref({
      firstName: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
      ],
      username: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
        { validator: validateUsername, trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "Yêu cầu nhập mật khẩu.",
          trigger: "blur",
        },
        {
          min: 6,
          max: 30,
          message: "Mật khẩu dài từ 6 đến 30 ký tự",
          trigger: "blur",
        },
      ],
    });

    const actionCreate = (par) => {
      return store.dispatch(AccountActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionCheckExist = (par) => {
      return store
        .dispatch(AccountActions.CHECK_USERNAME_EXIST, par)
        .catch((e) => {
          console.log(e);
        });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const stepResult: StepResult = await actionCreate(formData.value);
          hasResponse.value = true;
          isSuccess.value = stepResult.isSuccess;

          if (stepResult.isSuccess) {
            const peopleId = (stepResult.data as any).people_id;
            message.value = `ID mới được tạo là: ${peopleId}`;
            emit("change", {
              name: "studentId",
              value: peopleId,
            });
          } else {
            const msg = (stepResult.data as any).message;
            message.value = msg;

            if (msg === "Username bị trùng, vui lòng chọn username khác") {
              const resultCheckExist: StepResult = await actionCheckExist({
                username: formData.value.username,
              });

              if (resultCheckExist.isSuccess) {
                const peopleExisted: People = (resultCheckExist.data as any)
                  .people;

                isUsernameExistValid.value =
                  (resultCheckExist.data as any).isExist && peopleExisted;

                if (peopleExisted) {
                  similarPeople.value = peopleExisted as any;
                }
              }
            }
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      });
    };

    const apply = async () => {
      const currentValue = account.value;
      const gender = currentValue.gender.trim() === "1" ? "male" : "female";
      const birthday = transformBirthday(currentValue.birthday);

      formData.value = {
        firstName: currentValue.firstName.trim(),
        lastName: currentValue.lastName.trim(),
        username: currentValue.username.trim(),
        password: currentValue.password.trim(),
        role: "student",
        gender: gender,
        birthday: birthday, // YYYY-mm-dd
      };
      if (!formRef.value) {
        return;
      }
      // wait 1.5s for reactive then validate
      await new Promise((r) => setTimeout(r, 1500));

      formRef.value.validate();
    };

    onMounted(() => {
      apply();
    });

    const selectExistedPeople = () => {
      isSelectUsernameExisted.value = true;
      emit("change", {
        name: "studentId",
        value: similarPeople.value.id,
      });
    };

    return {
      selectExistedPeople,
      account,
      defaultData,
      labelGenders,
      formData,
      rules,
      submit,
      formRef,
      actionCreate,
      transformBirthday,
      apply,
      isSuccess,
      message,
      hasResponse,
      similarPeople,
      isUsernameExistValid,
      isSelectUsernameExisted,
    };
  },
});
