
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { StudentAccountInformation } from "@/store/vm/account/AccountModule";
import Mono from "@/views/account/child/MonoIndex.vue";
import { ClassActions } from "@/store/vm/class/enums";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "create-account-students",
  components: { Mono },
  methods: {
    async pushTotal() {
      for (let index = 0; index < this.numberAccountDetect; index++) {
        var element = (this.$refs as any).monoRefs[index];

        if (element.isSuccess) {
          continue;
        }

        ElNotification({
          title: "[Bắt đầu] Tài khoản nhãn " + (index + 1),
          message: "Đang xử lý ...",
          type: "info",
        });

        await element.push();
        await new Promise((r) => setTimeout(r, 1000));
      }
    },
  },
  setup() {
    const store = useStore();
    const urlGuide =
      "https://fate-option-40b.notion.site/C-ch-t-o-t-i-kho-n-h-ng-lo-t-cho-h-c-vi-n-trong-l-p-7bd666dc6df54e398a98aa957fb6f897";
    const monoRefs = ref();
    const fileContent = ref("");
    const numberAccountDetect = ref(0);
    const numberAccountSuccess = ref(0);
    const isShowCaseSuccess = ref(true);

    const listOrderSuccess: number[] = [];

    const accounts = ref<StudentAccountInformation[]>([]);

    const loadTextFromFile = (ev) => {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        fileContent.value = e?.target?.result as string;
        analyzeFile();
        return e?.target?.result;
      };
      reader.readAsText(file, "utf8");
    };

    const analyzeFile = () => {
      const lines = fileContent.value
        .split(/\r?\n/)
        .filter((m) => m.trim() !== "");
      numberAccountDetect.value = lines.length - 1;
      const data = lines.slice(1);
      accounts.value = data.map((line) => {
        const [
          lastName,
          firstName,
          username,
          password,
          birthday,
          gender,
          classCode,
        ] = line.split("\t");

        return {
          lastName,
          firstName,
          username,
          password,
          birthday,
          gender,
          classCode,
        };
      });
    };

    const listClass = computed(() => {
      return store.state.ClassModule.list;
    });

    onMounted(() => {
      if (listClass.value.length === 0) {
        store.dispatch(ClassActions.GET_ALL_CLASS).catch((e) => {
          console.log(e);
        });
      }
    });

    const onSuccess = (payload) => {
      const indexSuccess = payload.order;
      if (!isNaN(indexSuccess)) {
        if (!listOrderSuccess.includes(indexSuccess)) {
          listOrderSuccess.push(indexSuccess as number);
          numberAccountSuccess.value++;

          ElNotification({
            title: "[KQ] Tài khoản nhãn: " + (indexSuccess + 1),
            message: "Import thành công",
            type: "success",
          });
        }
      }
    };

    const onFail = (payload) => {
      const indexFail = payload.order;
      if (!isNaN(indexFail)) {
        ElNotification({
          title: "[KQ] Tài khoản nhãn " + (indexFail + 1),
          message: "Thất bại: " + payload.message,
          type: "error",
        });
      }
    };

    return {
      loadTextFromFile,
      fileContent,
      analyzeFile,
      numberAccountDetect,
      numberAccountSuccess,
      accounts,
      onSuccess,
      onFail,
      monoRefs,
      isShowCaseSuccess,
      urlGuide,
    };
  },
});
