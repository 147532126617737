
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ClassSelector from "@/views/class/selector/Class.vue";

export default defineComponent({
  name: "mono-select-class",
  components: {
    ClassSelector,
  },
  props: {
    classCode: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const listClass = computed(() => {
      return store.state.ClassModule.list;
    });

    const classIdTarget = ref(0);
    const isValidClassCode = ref(true);

    const classCodeProp = computed(() => {
      return props.classCode;
    });

    const apply = async () => {
      const currentValue = (classCodeProp.value as string).trim();

      const classTarget = listClass.value.find(
        (item) => item.code === currentValue
      );

      isValidClassCode.value = !!classTarget;

      if (classTarget) {
        classIdTarget.value = classTarget.class_id;
        emit("change", {
          name: "classId",
          value: classIdTarget.value,
        });
      }
    };

    onMounted(() => {
      apply();
    });

    const onChange = (payload) => {
      if (payload.name === "classId") {
        classIdTarget.value = payload.value;
        emit("change", {
          name: "classId",
          value: classIdTarget.value,
        });
      }
    };

    return {
      classCodeProp,
      listClass,
      apply,
      onChange,
      isValidClassCode,
      classIdTarget,
    };
  },
});
