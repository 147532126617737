
import { defineComponent, computed, ref, ComputedRef, onMounted } from "vue";
import { useStore } from "vuex";
import { StudentAccountInformation } from "@/store/vm/account/AccountModule";
import CreateAccount from "@/views/account/child/MonoCreateAccount.vue";
import ClassSelect from "@/views/account/child/MonoClassSelect.vue";
import { StepResult } from "@/store/captain/CaptainModule";
import { ClassActions } from "@/store/vm/class/enums";

export default defineComponent({
  name: "mono-create-account-student-index",
  emits: ["insertSuccess", "insertFail"],
  components: {
    CreateAccount,
    ClassSelect,
  },
  props: {
    information: {
      required: true,
      default: () => {
        return {};
      },
    },
    order: {
      required: true,
    },
    isShowCaseSuccess: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const createAccount = ref();
    const classIdTarget = ref(0);
    const studentIdTarget = ref(0);

    const hasResponse = ref(false);
    const isSuccess = ref(false);
    const isWarningAlreadyInClass = ref(false);
    const message = ref("");

    const account = computed(() => {
      return props.information;
    }) as ComputedRef<StudentAccountInformation>;

    const onChange = (payload) => {
      if (payload.name === "studentId") {
        studentIdTarget.value = payload.value;
      }

      if (payload.name === "classId") {
        classIdTarget.value = payload.value;
      }
    };

    const submitAddStudentToClass = async () => {
      const classId = classIdTarget.value;
      const studentId = studentIdTarget.value;

      const result: StepResult = await store
        .dispatch(ClassActions.ADD_STUDENT_TO_CLASS, {
          classId,
          studentId,
        })
        .catch((e) => {
          console.log(e);
        });

      hasResponse.value = true;
      isSuccess.value = result.isSuccess;
      message.value = result.data as string;

      if (result.isSuccess) {
        emit("insertSuccess", {
          order: props.order,
        });
      } else {
        if (result.data === "Học viên đã có trong danh sách lớp") {
          isSuccess.value = true;

          emit("insertSuccess", {
            order: props.order,
          });
          isWarningAlreadyInClass.value = true;
        } else {
          emit("insertFail", {
            order: props.order,
            message: result.data as string,
          });
        }
      }
    };

    const push = async () => {
      if (!isSuccess.value) {
        if (!studentIdTarget.value) {
          await createAccount.value.submit();

          // wait 0.3s - 3s for reactivity
          for (let i = 0; i < 10; i++) {
            if (!studentIdTarget.value) {
              await new Promise((r) => setTimeout(r, 300));
            }
          }
        }

        if (studentIdTarget.value && classIdTarget.value) {
          await submitAddStudentToClass();
        } else {
          const message = !studentIdTarget.value
            ? "Thông tin học viên ko hợp lệ"
            : "Thông tin lớp ko hợp lệ";

          emit("insertFail", {
            order: props.order,
            message,
          });
        }
      }
    };

    return {
      message,
      onChange,
      account,
      hasResponse,
      isSuccess,
      classIdTarget,
      studentIdTarget,
      submitAddStudentToClass,
      createAccount,
      push,
      isWarningAlreadyInClass,
    };
  },
});
